<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">{{stu != 'zfjg' ? '审核管理' : '培训管理'}}</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">{{stu != 'zfjg' ? '班级审核' : '班级审批'}}</a>
          <i>></i>
           <a href="javascript:;" @click="init" class="cur-a">学员详情</a>
        </span>
      </div>
      <div class="framePage-body">
          <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >手机号码</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="手机号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div class="btnBox" style="margin-left:20px">
              <el-button
                style="margin-left:20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
           
           
          </div>
        </div>
         <div style="margin: 15px 0 5px">
          <span>班级名称：{{ projectName }}</span>
          <span style="margin:0 30px;">班级编码：{{ projectCode }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                min-width="150px"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                min-width="250px"
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                min-width="250px"
              />
              <el-table-column
                label="入班时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="250px"
              />
             <el-table-column
            label="操作"
            width="130px"
            show-overflow-tooltip
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="
                  doDel(
                    scope.row.userId,
                    scope.row.userName,
                    scope.row.idcard,
                  )
                "
              >移出学员</el-button>
            </template>
          </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import ClassToBeReviewed from "@/views/AuditManagement/ClassToBeReviewed.vue";
import { resetKeepAlive } from "@/utils/common";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
export default {
  name: "studentDetail",
  components: {
     Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
       userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
        projectName:this.$route.query.projectName ,
        projectCode: this.$route.query.projectCode,
        stu:this.$route.query.stu
    };
  },
  created() {
  },
  mounted() {
    
  },
  methods: {
      superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    getData(pageNum = 1) {
        const params = {
            pageNum: pageNum,
            pageSize: this.pageSize,
            projectId:this.$route.query.projectId
        };
        if(this.userId) {
          params.userId = this.userId
        }
        this.doFetch({
            url: "/biz/projectUser/listPage",
            params,
            pageNum,
        });
    },
     // 删除
    doDel(userId,userName,idcard) {
      let params = {
        userId: userId,
        projectId: this.$route.query.projectId
      };
      this.$confirm("你确定要将" +userName+  "（" + idcard+"）" + "移出班级吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("/biz/projectUser/removeCourseUserGovernment", params).then(res => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: res.message
              });
              this.getData(-1);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // });
        });
    }
  },

  watch: {
  }
  
};
</script>
<style lang="less">
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
